'use strict';

angular.module('enervexSalesappApp').controller('LocationDataCtrl', function($scope, LocationData, $stateParams, Flash, $state, _) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	if ($stateParams.id == "new") {
		$scope.item = {
			state: "",
			subState: 0,
			active: true
		}
	} else {
		LocationData.get({
			id: $stateParams.id
		}).$promise.then(function(item) {
			$scope.item = item;
		})
	}

	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			payload.state = $scope.item.state._id
			payload.county = $scope.item.county._id
			if (!$scope.item._id) {
				// LocationData.save(payload).$promise.then(function(result) {
					
				// 	$scope.success = true;
				// 	Flash.create('success', '<strong>Success!</strong> Successfully created location data.', 0, {}, true);
				// 	$state.go("location-data", {
				// 		id: result._id
				// 	}, {
				// 		inherit: false,
				// 		reload: true
				// 	})
				// }).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				// })
			} else {
				LocationData.update({
					id: $scope.item._id
				}, payload).$promise.then(function(result) {
					
					$scope.success = true;
					Flash.create('success', '<strong>Success!</strong> Successfully updated location data.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});